import React from "react"

import { StaticQuery, graphql } from "gatsby"
import Text from "../../components/text"

import CtaBig from "../cta-big"

import styles from "./component.module.less"

export default props => {
  const rows = (props.rows || []).filter(
    r =>
      (r.text !== undefined || r.text !== null || r.text.length > 0))
    
  return (
    <StaticQuery
      query={graphql`
        query {
          allDatoCmsConfig {
            nodes {
              companyAddress
              companyAddressCity
              companyAddressZip
              additionalInformations
              companyFax
              companyMail
              companyPhone
              differentOpen
              differentWeekdays
              facebookLink
              regularWeekdays
              regularOpen
            }
          }
        }
      `}
      render={rawData => {

        const data = rawData.allDatoCmsConfig.nodes[0];

        return (
          <CtaBig
        id={props.id}
        className={props.className}
        decoration={props.decoration}
        title={props.title}
        headline={props.headline}
        htmlHeadline={props.htmlHeadline}
        text={props.text}
        buttonText={props.buttonText}
        buttonUrl={props.buttonUrl}
      >
        {rows.length > 0 && (
          <div className={styles.boxes}>
            {rows.map((row, i) => (
              <div key={i} className={styles.box}>
                <Text
                  title={row.title}
                  htmlHeadline={row.htmlHeadline}
                  htmlText={row.text}
                />
                {props.showContact === true && (
                  <>
                    {i === 0 && (
                      <>
                        <div className={styles.address}>
                          {data.companyAddress},{" "}
                          {data.companyAddressZip}{" "}
                          {data.companyAddressCity.split(",")[0]}
                        </div>
                        <div className={styles.address} style={{paddingBottom: "1rem"}}>{data.additionalInformations}</div>
                        {data.companyMail && 
                          <div className={styles.contact}>
                            <div className={styles.mail}/> <div>{data.companyMail}</div>
                          </div>}
                        {data.companyPhone &&
                          <div className={styles.contact}>
                            <div className={styles.phone}/> <div>{data.companyPhone}</div> 
                          </div>}
                        {data.facebookLink && 
                          <div className={styles.contact}>
                            <div className={styles.facebook}/> <a className={styles.link} href={data.facebookLink}>Folgen sie uns auf Facebook</a>
                          </div>}
                      </>
                    )}
                    {i === 1 && (
                      <div>
                        <div className={styles.row}>
                          <div className={styles.days}>{data.regularWeekdays}</div>
                          <div className={styles.time}>{data.regularOpen}</div>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.days}>{data.differentWeekdays}</div>
                          <div className={styles.time}>{data.differentOpen}</div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </CtaBig>
        )
      }}
    ></StaticQuery>
  )
}

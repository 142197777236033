import React from "react"

import ContentContainer from '../../components/content-container'

import styles from './component.module.less'

export default function(props) {
  if (!props.children) {
    return null;
  }

  const renderContent = (standalone) => {
    let containerClasses = [styles.container];
    if (props.containerClassName) {
      containerClasses.push(props.containerClassName);
    }
    containerClasses = containerClasses.join(' ');

    return (
        <>
          {standalone &&
            <div className={containerClasses}>
              {props.children}
            </div>
          }

          {!standalone &&
            <ContentContainer className={containerClasses}>
              {props.children}
            </ContentContainer>
          }
        </>
    );
  };

  let classNames = [styles.quote];
  if (props.className) {
    classNames.push(props.className);
  }
  classNames = classNames.join(' ');

  if (props.standalone === undefined || props.standalone === false) {
    return (
        <section className={classNames}>
          {renderContent(false)}
        </section>
    );
  }

  return (
      <div className={classNames}>
        {renderContent(true)}
      </div>
  );
}

import React, { useEffect, useLayoutEffect, useState } from "react"

import TextDecorator from '../../components/text-decorator'
import styles from './component.module.less'
import ContentContainer from "../../components/content-container"

export default (props) => {

    let classNames = [styles.container];
    let rowClass = [styles.row];
    let decoratorClass = [styles.decorator];
    let textClass = [styles.text];
    const [widthBase, setWidthBase] = useState("");

    if (props.containerClass) {
        classNames = [props.containerClass];
    }
    if (props.background && props.background === "Rot") {
        classNames.push(styles.background);
    }

    function handleResize() {
        if (window.innerWidth <= 780) {
            setWidthBase("50vw");
        } else if (window.innerWidth <= 940) {
            setWidthBase("38vw");
        } else if (window.innerWidth <= 1200) {
            setWidthBase("28vw");
        } else if (window.innerWidth <= 1280) {
            setWidthBase("23vw");
        } else {
            setWidthBase("18vw");
        }
    }

    useEffect(() => {
        handleResize();
    });

    useLayoutEffect(() => {
        window.addEventListener("resize", handleResize)
    });

    return (
        <section>
            <ContentContainer className={classNames.join(' ')}>
                {props.decorator && <TextDecorator className={decoratorClass.join(' ')} color1={"#fff"} color2={(props.background === "Rot") ? "#B4522F" : "#E8C5A1"} widthBase={widthBase}>{props.decorator}</TextDecorator> }
                <div className={rowClass.join(' ')}>
                    <div className={textClass.join(' ')}>
                        <span style={{fontWeight:"100"}}>{props.product.name}</span>{props.textSuffix && <> {props.textSuffix}</>}
                        <div className={styles.subtitle} dangerouslySetInnerHTML={{__html: props.text}}/>
                    </div>
                    {props.product.image &&
                        <img className={styles.image} src={props.product.image.fluid.src} alt={props.product.image.alt}/>
                    }
                </div>
            </ContentContainer>
        </section>
    )
}

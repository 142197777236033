import React from "react"
import {graphql, StaticQuery} from "gatsby"

import remoteAssetUrl from "../../lib/remote-asset-url";

import Caption from '../../components/caption'
import ContentContainer from '../../components/content-container'

import styles from './component.module.less'

class Video extends React.Component {

  locale;
  videoRef;

  constructor(props) {
    super(props);

    this.locale = props.locale || 'de';
    this.videoRef = React.createRef();

    this.state = {
      play: false
    };

    this.onPlayClick = this.onPlayClick.bind(this);
  }

  onPlayClick() {
    if (!this.videoRef.current) {
      return;
    }

    this.setState(() => ({
      play: true
    }));

    this.videoRef.current.play();
    this.videoRef.current.controls = true;
  }

  render() {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allDatoCmsTemplateTexte {
                        nodes {
                            locale
                            videoPlayButtonLabel
                        }
                    }
                }
            `}
            render={data => {
              const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === this.locale);
              return (
                  <section id={this.props.id} className={`${styles.video}${this.props.className ? ' ' + this.props.className : ''}${this.state.play ? ' ' + styles.playing : ''}`}>
                    <ContentContainer className={styles.contentContainer}>

                      <div className={styles.videoContainer}>
                        {/* eslint-disable jsx-a11y/media-has-caption */}
                        <video
                            controls={false}
                            poster={remoteAssetUrl(this.props.posterSrc)}
                            data-setup="{}"
                            ref={this.videoRef}
                        >
                          <source src={remoteAssetUrl(this.props.videoSrc)} type="video/mp4" />
                        </video>
                        {/* eslint-enable jsx-a11y/media-has-caption */}

                        <div className={styles.overlay}>
                          <button onClick={this.onPlayClick}>{templateStrings.videoPlayButtonLabel}</button>
                        </div>
                      </div>

                      {(this.props.title || this.props.caption) &&
                        <Caption className={styles.caption}>
                          {this.props.title && <span>{this.props.title}</span>}
                          {this.props.caption && <em>{this.props.caption}</em>}
                        </Caption>
                      }

                    </ContentContainer>
                  </section>
              );
            }}
        />
    );
  }

}

export default Video;

import React from "react"

import Text from  '../../components/text'
import TextDecorator from '../../components/text-decorator'

import styles from './component.module.less'

export default (props) => {
  let classNames = [styles.ctaBig];

  if (props.className) {
    classNames.push(props.className);
  }

  if (props.children && React.Children.count(props.children) > 0) {
    classNames.push(styles.withCntnt);
  }

  classNames = classNames.join(' ');

  return (
      <section id={props.id} className={classNames}>
        {props.decoration &&
          <TextDecorator
              className={styles.decorator}
              color1="#C09A5D"
              color2="rgba(255, 255, 255, .31)"
          >{props.decoration}</TextDecorator>
        }

        <div className={styles.container}>
          <Text
              header={true}
              dark={true}
              title={props.title}
              headline={props.headline}
              htmlHeadline={props.htmlHeadline}
              htmlText={props.text}
              headlineClassName={styles.headline}
          />
        </div>

        {props.buttonText && props.buttonUrl &&
          <a className={styles.cta} href={props.buttonUrl}>{props.buttonText}</a>
        }

        {props.children}
      </section>
  );
}

import React from "react"

import Button from '../../components/button'

import styles from './component.module.less'

export default function(props) {
  return (
      <Button href={props.href} theme="outline-rounded" className={`${styles.pill}${props.className ? ' ' + props.className : ''}`}>
        {props.children}
      </Button>
  );
}

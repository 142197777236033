import React from "react"

import Button from "../../components/button"
import ContentContainer from '../../components/content-container'
import Text from '../../components/text'
import TextDecorator from '../../components/text-decorator'

import styles from "./component.module.less"

export default (props) => (
  <section id={props.id} className={`${styles.container} ${props.className ? ' ' + props.className : ''}`}>

    <div className={styles.benefitsContainer}>
      <div className={styles.benefits}>
        <ContentContainer className={styles.container}>
          {props.benefitHeadline &&
            <p className={styles.headline}>{props.benefitHeadline}</p>
          }
          <div className={styles.list}>
            {[props.benefit1, props.benefit2, props.benefit3, props.benefit4].filter(b => b && b.length > 0).map((benefit, i) => (
                <div key={i} className={styles.listItem}>
                  <div className={styles.benefitsNumber}>
                    {i+1}
                  </div>
                  <span className={styles.benefitsList}>{benefit}</span>
                </div>
            ))}
          </div>
        </ContentContainer>
      </div>
    </div>

    <div className={styles.benefitsDescription}>
      <Text
          className={styles.txt}
          title={props.title}
          headline={props.headline}
          htmlText={props.htmlText}
      >
        {props.text}
      </Text>

      {props.button1Text && props.button1Url &&
        <Button theme="block" href={props.button1Url} className={styles.requestButton}>{props.button1Text}</Button>
      }

      {props.button2Text && props.button2Url &&
        <Button theme="block" href={props.button2Url} className={styles.requestButton}>{props.button2Text}</Button>
      }
    </div>

    {props.textDecorator &&
      <TextDecorator className={styles.decorator} widthOffset={-130}>{props.textDecorator}</TextDecorator>
    }
  </section>
)

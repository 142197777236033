import React from "react"

import styles from './component.module.less'

export default function(props) {
  return (
      <span className={`${styles.caption}${props.className ? ' ' + props.className : ''}`}>
        {props.children}
      </span>
  );
}

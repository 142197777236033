import React from "react"

import remoteAssetUrl from '../../lib/remote-asset-url'

import ContentContainer from '../../components/content-container'

import styles from './component.module.less'

export default function(props) {
  const quotes = props.quotes || [];

  if (quotes.length === 0) {
    return null;
  }

  return (
      <section id={props.id} className={styles.quoteP}>
        <ContentContainer className={styles.container}>
          {quotes.map((quote, i) => (
              <div key={i} className={`${styles.item}${quote.person && quote.person.image ? ' ' + styles.withImg : ''}`}>
                {quote.person && quote.person.image &&
                    <img src={remoteAssetUrl(quote.person.image.fluid.src)} alt={quote.person.image.alt} />
                }

                <blockquote>
                  "{quote.text}"
                </blockquote>

                {quote.person && quote.person.name &&
                  <em>{quote.person.name}</em>
                }
              </div>
          ))}
          {props.children}
        </ContentContainer>
      </section>
  );
}

import React from "react"

import ContentElements from '../../components/content-elements'

export default (props) => (
    <ContentElements
        items={props.items}
        locale={props.locale}
        config={props.config}
        classNames={props.classNames}
        mutators={props.mutators}
    />
)

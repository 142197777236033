import React from "react"
import {graphql, StaticQuery} from "gatsby"

import searchPath from '../../lib/search-path'

import Button from '../../components/button'
import ContentContainer from '../../components/content-container'
import Pill from '../../components/pill'
import Text from '../../components/text'

import styles from './component.module.less'

export default function(props) {
  const locale = props.locale || 'de';

  return (
      <StaticQuery
          query={graphql`
              query {
                  allDatoCmsTemplateTexte {
                      nodes {
                          locale
                          searchCeInputPlaceholder
                          searchCeButtonLabel
                      }
                  }
              }
          `}
          render={data => {
            const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);

            return (
                <ContentContainer id={props.id} tag="section" className={`${styles.search}${props.className ? ' ' + props.className : ''}`}>
                  {(props.title || props.titleHtml) &&
                    <Text
                        className={styles.header}
                        center={true}
                        title={props.title}
                        titleHtml={props.titleHtml}
                        titleClass={styles.title}
                    />
                  }

                  <form className={styles.form} action={searchPath(locale)}>
                    <div className={styles.inner}>
                      <input type="search" name="s" placeholder={templateStrings.searchCeInputPlaceholder} />

                      <Button className={styles.btn} type="submit" theme="outline">
                        {templateStrings.searchCeButtonLabel}
                      </Button>
                    </div>

                    {props.tags &&
                      <ul className={styles.tags}>
                        {props.tags.map((t, i) => (
                            <Pill key={i} className={styles.tag} href={t.url}>{t.name}</Pill>
                        ))}
                      </ul>
                    }
                  </form>
                </ContentContainer>
            );
          }}
      />
  );
}

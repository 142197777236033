import React from "react"
import Color from "color"

import styles from './component.module.less'

export default function(props) {
  if (!props.children) {
    return null;
  }

  let classNames = [styles.textDecorator];
  if (props.className) {
    classNames.push(props.className);
  }

  let color1 = '#E8C5A1';
  let color2 = '#3F2D22';

  if (props.baseColor) {
    const baseColor = Color(props.baseColor || '#E8C5A1');
    color1 = baseColor.darken(0.32).hex();
  }

  if (props.color1) {
    color1 = props.color1;
  }

  if (props.color2) {
    color2 = props.color2;
  }

  const text = props.children.toString();

  return (
      <div className={classNames.join(' ')} style={{
        fontSize: 'calc((' + (props.widthBase || '75vw') + ' + ' + (props.widthOffset || 0) + 'px) / (' + text.length +  '/ 1.8))',
        lineHeight: 'calc((' + (props.widthBase || '75vw') + ' + ' + (props.widthOffset || 0) + 'px) / (' + text.length +  '/ 2.1))',
        background: '-webkit-linear-gradient(' + color1 + ', ' + color2 + ')',
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
      }}>
        {text}
      </div>
  )
}

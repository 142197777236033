export default (src) => {
  if (!src || src.length === 0) {
    return '';
  }

  if (!src.match(/^https:\/\/www\.datocms-assets\.com\//)) {
    return src;
  }

  const path = src.replace(/https:\/\/www\.datocms-assets\.com\//, '');

  if (process.env.GATSBY_FEATURE_ASSET_SEOFILENAME !== '1') {
    return process.env.GATSBY_WEB_ASSET_REMOTE + '/' + path;
  }

  // GATSBY_FEATURE_ASSET_SEOFILENAME enabled

  const pathMatch = path.match(/^([0-9]{1,99})\/([0-9]{1,99})-/);
  if (!pathMatch || pathMatch.length !== 3) {
    return process.env.GATSBY_WEB_ASSET_REMOTE + '/' + path;
  }

  let newPath = path.replace(pathMatch[0], '');
  if (path.includes('?')) {
    newPath += '&';
  } else {
    newPath += '?';
  }

  newPath += 'mp_dir=' + pathMatch[1] + '&mp_id=' + pathMatch[2];

  return process.env.GATSBY_WEB_ASSET_REMOTE + '/' + newPath;
}

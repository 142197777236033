import React from "react"
import {graphql, StaticQuery} from "gatsby"

import ContentContainer from '../../components/content-container'
import AdvisorItem from  '../../components/advisor-item'

import styles from './component.module.less'

const swipeThreshold = 80;

export class SliderContentElement extends React.Component {

  locale;
  itemCount;
  itemWidthPercentage;
  swipeStartX;

  constructor(props) {
    super(props);

    this.locale = props.locale || 'de';

    this.state = {
      index: 0,
    };

    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  onNextClick() {
    if (this.state.index >= this.itemCount - 1) {
      return;
    }

    this.setState({
      index: this.state.index + 1,
    });
  }

  onPrevClick() {
    if (this.state.index === 0) {
      return;
    }

    this.setState({
      index: this.state.index - 1,
    });
  }

  onTouchStart(e) {
    this.swipeStartX = e.changedTouches[0].clientX;
  }

  onTouchEnd(e) {
    const swipeDelta = this.swipeStartX - e.changedTouches[0].clientX;
    if (Math.abs(swipeDelta) < swipeThreshold) {
      return;
    }

    if (swipeDelta > 0) {
      this.onNextClick();
    } else {
      this.onPrevClick();
    }
  }

  render() {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allDatoCmsTemplateTexte {
                        nodes {
                            locale
                            pagerPrevLabel
                            pagerNextLabel
                        }
                    }
                
                    allDatoCmsRatgeber(sort: {fields: publicationDate, order: DESC}) {
                        nodes {
                            ...advisor_item
                        }
                    }
                }
            `}
            render={data => {
              const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === this.locale);

              const items = data.allDatoCmsRatgeber.nodes
                  .filter(n => n.locale === this.locale)
                  .filter(n => n.slug != null)
                  .filter((n, i) => i < 4);

              if (items.length === 0) {
                return null;
              }

              this.itemCount = items.length;
              this.itemWidthPercentage = Math.floor(100 / this.itemCount);

              return (
                <section
                    onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onTouchEnd}
                    id={this.props.id}
                    className={`${styles.slider}${this.props.className ? ' ' + this.props.className : ''}`}
                >
                  <ContentContainer>
                    <header>
                      {this.props.headline &&
                        <h2 className={styles.headline}>{this.props.headline}</h2>
                      }

                      <button onClick={this.onPrevClick} disabled={this.state.index === 0} className={`${styles.pager}`}>
                        {templateStrings.pagerPrevLabel}
                      </button>

                      <button onClick={this.onNextClick} disabled={this.state.index >= this.itemCount - 1} className={`${styles.pager}`}>
                        {templateStrings.pagerNextLabel}
                      </button>
                    </header>

                    <ol className={styles.list} style={{
                      transform: 'translateX(' + (this.state.index * this.itemWidthPercentage) * -1 + '%)'
                    }}>
                      {items.map((item, i) => (
                          <AdvisorItem
                              key={i}
                              locale={this.locale}
                              data={item}
                              urlPrefix={this.props.urlPrefix}
                              style={{
                                width: this.itemWidthPercentage + '%',
                              }}
                          />
                      ))}
                    </ol>
                  </ContentContainer>
                </section>
            )
          }}
        />
    );
  }
}

export default SliderContentElement;

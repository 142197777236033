export default (input) => {
  if (!input) {
    return null;
  }

  return input
      .toLowerCase()
      .replace(/ä/, 'ae')
      .replace(/ö/, 'oe')
      .replace(/ü/, 'ue')
      .replace(/ß/, 'ss')
      .replace(/[\W_]+/g, '');
}

import React from "react"

import remoteAssetUrl from '../../lib/remote-asset-url'

import styles from './component.module.less'

export default function(props) {

  const locale = props.locale || 'de';

  const item = props.data || {};

  const renderContent = () => (
      <a href={(props.urlPrefix || '/') + item.slug + '/'}>
        <img src={remoteAssetUrl(item.image.fluid.src)} alt={item.image.alt} />
        <span className={styles.desc}>
          <span className={styles.title}>{item.title}</span>
          <span className={styles.txt}>{item.teasertext}</span>
        </span>
      </a>
  );

  let classNames = [styles.item];
  if (locale !== 'de') {
    classNames.push(styles.en);
  }
  if (props.className) {
    classNames.push(props.className);
  }
  classNames = classNames.join(' ');

  switch (props.tag) {
    case 'div':
      return (<div className={classNames} style={props.style}>{renderContent()}</div>);
    default:
      return (<li className={classNames} style={props.style}>{renderContent()}</li>);
  }
}

import React from "react"
import {graphql, StaticQuery} from "gatsby"

import remoteAssetUrl from '../../lib/remote-asset-url'

import Button from '../../components/button'
import ContentContainer from '../../components/content-container'

import styles from './component.module.less'

const CMSfinderValues = [
    ['langfristig und konstant', 'flexibel und individuell'],
    ['Metallmix', 'Spezielles Metall'],
    ['Klassisches Edelmetall', 'Innovatives Technologiemetall'],
];

class SavingsPlanFinder extends React.Component {

  locale;
  config;
  productFilters;

  constructor(props) {
    super(props);

    this.locale = props.locale || 'de';

    this.state = {
      allSelected: false,
      selected: [],
      products: [],
    };

    this.onSelect = this.onSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSelect(e) {
    const row = parseInt(e.target.getAttribute('data-row'));
    const value = e.target.getAttribute('data-value');

    const selected = this.state.selected;
    selected[row] = value;

    this.setState({
      allSelected: selected.length === CMSfinderValues.length,
      selected: selected,
    }, () => {
      if (this.state.allSelected) {
        this.onSubmit();
      }
    });
  }

  onSubmit() {
    const productsByRules = this.productFilters
        // find all rules that apply to the user's selection
        .filter(pf => {
          const s = this.state.selected.map((selectedIndex, row) => CMSfinderValues[row][selectedIndex]);
          return pf.occasion === s[0] && pf.productType === s[1] && pf.metalType === s[2];
        })
        // filter the rule's products based on the current locale and return them
        .flatMap(pf => pf.products.filter(p => p.locale === this.locale));

    // unique products
    let products = [];
    productsByRules.forEach(p => {
      const isIn = products.findIndex(v => v.id === p.id) >= 0;
      if (!isIn) {
        products.push(p);
      }
    });

    this.setState({
      products,
    });
  }

  render() {
    return (
        <StaticQuery
            query={graphql`
              query {
                  allDatoCmsTemplateTexte {
                      nodes {
                          locale
                          productfinderEmptyResultsTxt
                          productfinderResultLabel
                          productfinderOccasionLabel
                          productfinderProducttypeLabel
                          productfinderMetaltypeLabel
                          productfinderOccasion1
                          productfinderOccasion2
                          productfinderProducttype1
                          productfinderProducttype2
                          productfinderMetaltype1
                          productfinderMetaltype2
                      }
                  }
              
                  allDatoCmsProduktfinder {
                      nodes {
                          locale
                          occasion
                          productType
                          metalType
                          products {
                              id
                              locale
                              name
                              slug
                          }
                      }
                  }
                
                  allDatoCmsConfig {
                      nodes {
                          locale
                          productUrlPrefix
                      }
                  }
              }
            `}
            render={data => {
              const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === this.locale);

              this.productFilters = data.allDatoCmsProduktfinder.nodes.filter(f => f.locale === this.locale);
              this.config = data.allDatoCmsConfig.nodes.find(c => c.locale === this.locale) || {productUrlPrefix: ''};

              return (
                  <section id={this.props.id} className={this.props.className ? ' ' + this.props.className : ''}>
                    <ContentContainer className={styles.savingsPlanFinder}>

                      <div className={styles.finder}>
                        {this.props.image &&
                          <div
                              className={styles.deco}
                              style={{
                                backgroundImage: 'url(' + remoteAssetUrl(this.props.image.fluid.src) + ')'
                              }}
                          />
                        }

                        {this.props.htmlHeadline &&
                          <div className={styles.title} dangerouslySetInnerHTML={{__html: this.props.htmlHeadline}} />
                        }

                        {this.props.headline &&
                          <span className={styles.title}>{this.props.headline}</span>
                        }

                        <div className={styles.row}>
                          <span className={styles.lbl}>{templateStrings.productfinderOccasionLabel}</span>
                          {this.renderRowButton(0, 0, templateStrings.productfinderOccasion1)}
                          <span className={styles.lbl}>
                            {this.locale === 'de' && <>oder</>}
                            {this.locale === 'en' && <>or</>}
                          </span>
                          {this.renderRowButton(0, 1, templateStrings.productfinderOccasion2)}
                        </div>
                        <div className={styles.row}>
                          <span className={styles.lbl}>{templateStrings.productfinderProducttypeLabel}</span>
                          {this.renderRowButton(1, 0, templateStrings.productfinderProducttype1)}
                          <span className={styles.lbl}>
                            {this.locale === 'de' && <>oder</>}
                            {this.locale === 'en' && <>or</>}
                          </span>
                          {this.renderRowButton(1, 1, templateStrings.productfinderProducttype2)}
                        </div>
                        <div className={styles.row}>
                          <span className={styles.lbl}>{templateStrings.productfinderMetaltypeLabel}</span>
                          {this.renderRowButton(2, 0, templateStrings.productfinderMetaltype1)}
                          <span className={styles.lbl}>
                            {this.locale === 'de' && <>oder</>}
                            {this.locale === 'en' && <>or</>}
                          </span>
                          {this.renderRowButton(2, 1, templateStrings.productfinderMetaltype2)}
                        </div>

                        {/*}
                        <Button onClick={this.onSubmit} theme="outline" className={styles.submit} disabled={!this.state.allSelected}>
                          {this.props.buttonText &&
                            <>{this.props.buttonText}</>
                          }
                          {!this.props.buttonText &&
                            <>
                              {this.locale === 'de' &&
                                <>Sparplan suchen</>
                              }
                              {this.locale === 'en' &&
                                <>Search</>
                              }
                            </>
                          }
                        </Button>
                        {*/}

                        {/* product list */}
                        {this.renderProducts(templateStrings.productfinderEmptyResultsTxt, templateStrings.productfinderResultLabel)}
                      </div>

                      {/* aside teaser */}
                      {this.renderAside()}

                    </ContentContainer>
                  </section>
              );
            }}
        />
    );
  }

  renderRowButton(row, value, label) {
    const isActive = this.state.selected[row] && this.state.selected[row] === value.toString();

    return (
        <Button
            onClick={this.onSelect}
            theme={isActive ? 'block-rounded' : 'outline-rounded'}
            className={`${styles.btn}${!isActive ? ' ' + styles.outline : ''}`}
            data-row={row}
            data-value={value}
        >
          {label}
        </Button>
    );
  }

  renderProducts(emptyText, resultLabel) {
    if (!this.state.allSelected) {
      return null;
    }

    if (this.state.products.length === 0) {
      return (
          <ol className={styles.products}>
            <li>{emptyText}</li>
          </ol>
      );
    }

    return (
        <ol className={styles.products}>
          <li className={styles.lbl}>{resultLabel}</li>
          {this.state.products.map((p, i) => (
              <li key={i}>
                <Button
                    theme="block"
                    href={'/' + this.locale + (this.config.productUrlPrefix ? '/' +  this.config.productUrlPrefix : '') + '/' + p.slug + '/'}
                >{p.name}</Button>
              </li>
          ))}
        </ol>
    );
  }

  renderAside() {
    return (
        <>
          {(this.props.sidebarHeadline || this.props.sidebarHtmlText || this.props.sidebarText || (this.props.sidebarButtonText && this.props.sidebarButtonUrl)) &&
            <div className={styles.contact}>
              {this.props.sidebarImage &&
                <div
                    className={styles.deco}
                    style={{
                      backgroundImage: 'url(' + remoteAssetUrl(this.props.sidebarImage.fluid.src) + ')'
                    }}
                />
              }

              {this.props.sidebarHeadline &&
                <span className={styles.title}>{this.props.sidebarHeadline}</span>
              }

              {this.props.sidebarHtmlText &&
                <div className={styles.text} dangerouslySetInnerHTML={{__html: this.props.sidebarHtmlText}} />
              }

              {this.props.sidebarText &&
                <div className={styles.text}>{this.props.sidebarText}</div>
              }

              {this.props.sidebarButtonText && this.props.sidebarButtonUrl &&
                <Button theme="block" href={this.props.sidebarButtonUrl} className={styles.continue}>
                  {this.props.sidebarButtonText}
                </Button>
              }
            </div>
          }
        </>
    );
  }
}

export default SavingsPlanFinder;

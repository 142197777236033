import React from "react"
import VisibilitySensor from "react-visibility-sensor"

export default props => {
    if (props.onVisibilityChange && props.payload) {
        return (
            <VisibilitySensor
                offset={{top: 260, bottom: 260}}
                resizeCheck={props.resizeCheck || false}
                partialVisibility={props.partialVisibility || true}
                onChange={isVisible => {
                    props.onVisibilityChange(props.payload || null, isVisible);
                }}
            >{props.children}</VisibilitySensor>
        );
    }

    return (<>{props.children}</>);
}

import React from "react"

import ContentContainer from '../../components/content-container'

import styles from './component.module.less'

export default (props) => (
    <section id={props.id} className={`${styles.benefits}${props.className ? ' ' + props.className : ''}`}>
      <ContentContainer className={styles.container}>
        {(props.items || []).filter(b => b.length > 0).map((benefit, i) => (
            <span key={i}>{benefit}</span>
        ))}
        {props.children}
      </ContentContainer>
    </section>
)
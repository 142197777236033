import React from "react";
import styles from './component.module.less'
import Text from "../../components/text"
import Video from "../video"

export default (props) => {

    return (
        <section className={styles.container}>
            <Text
                className={styles.text}
                htmlHeadline={props.htmlHeadline}
                center={true}
            >

            </Text>
            <Video
                className={styles.video}
                videoSrc={props.video.url}
            />
            <div className={styles.row}>
                <img className={styles.imageLeft}  src={props.image1.fluid.src} alt={props.image1.alt}/>
                <Text
                    className={styles.text}
                    htmlHeadline={props.headline}
                    htmlText={props.text}
                    headlineClassName={styles.headline}
                >

                </Text>
                <img className={styles.image} src={props.image2.fluid.src} alt={props.image2.alt}/>
            </div>
        </section>
    )
}